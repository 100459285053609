import {
  BillingDirectoryEntity,
  DirectoryRecord,
  DocsPackageDirectoryEntity,
  DocumentDirectoryEntity,
  DocumentTypeEntity,
  LegalFormEntity,
  NalogTypeEntity,
  OrdersDirectoryEntity,
  TicketTitleEntity,
  TransportDirectoryEntity,
} from "@/domain";
import { TicketStatusEntity } from "@/domain/tickets";
import {
  AgDirDefaultEntity,
  AgDirTypeEntityFront,
  DictionaryBillingController_BillingDictionaryResponse,
  DirDefaultEntity,
  DirDocsAllController_AgDirDocsAllResponse,
  DirDocumentCustomerController_AgDirDocumentAllResponse,
  DirDocumentMoverController_AgDirDocumentAllResponse,
  DirTicketTitleEntity,
  DirTransportAllController_AgTransportDictionaryResponse,
  GetDictionaryOrdersController_AgOrdersDictionaryResponse,
} from "../codegen";

export function toTicketStatusEntity(entity: DirDefaultEntity): TicketStatusEntity {
  return {
    id: entity.value,
    name: entity.label,
    description: entity.description ?? undefined,
    active: entity.active,
  };
}

export function toTicketTitleEntity(entity: DirTicketTitleEntity): TicketTitleEntity {
  return {
    id: entity.value,
    name: entity.label,
    description: entity.description ?? undefined,
    active: entity.active,
    readonly: entity.read_only,
  };
}

export function toDirectoryRecord(entity: AgDirDefaultEntity): DirectoryRecord {
  return {
    value: entity.value,
    label: entity.label,
    description: entity.description ?? undefined,
    active: entity.active ?? false,
  };
}

export function toNalogTypeEnity(entity: AgDirDefaultEntity): NalogTypeEntity {
  return toDirectoryRecord(entity);
}

export function toLegalFormEnity(entity: AgDirDefaultEntity): LegalFormEntity {
  return toDirectoryRecord(entity);
}

export function toDocumentTypeEntity(entity: AgDirTypeEntityFront): DocumentTypeEntity {
  return {
    id: entity.value,
    description: entity.description ?? undefined,
    groupDocument: entity.group_document,
    groupLabel: entity.group_label,
    label: entity.label,
    labelDocument: entity.label_document,
    active: entity.active,
  };
}

export function toTransportDirectoryEntity(
  entity: DirTransportAllController_AgTransportDictionaryResponse,
): TransportDirectoryEntity {
  return {
    partnershipForms: entity.dir_partnership_form.map(toDirectoryRecord),
    ownershipForms: entity.dir_ownership_form.map(toDirectoryRecord),
    semitrailerSizes: entity.dir_size_type.map(toDirectoryRecord),
    truckOptions: entity.dir_truck_options.map(toDirectoryRecord),
    semitrailerOptions: entity.dir_semi_trailer_options.map(toDirectoryRecord),
    driverOptions: entity.dir_driver_options.map(toDirectoryRecord),
    brandTypes: entity.dir_brand.map((i) => ({ id: i.value, name: i.name_ru })),
    activityStatuses: entity.dir_activity_status.map(toDirectoryRecord),
  };
}

export function toBillingDirectoryEntity(
  response: DictionaryBillingController_BillingDictionaryResponse,
): BillingDirectoryEntity {
  return {
    invoiceStatuses: response.dir_invoice_status.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: true,
    })),
    fineStatuses: response.dir_fine_status.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: true,
    })),
    creditStatuses: response.dir_credit_status.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: true,
    })),
    operatingTypes: response.dir_operating_type.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: true,
    })),
    withdrawalsStatuses: response.dir_withdrawal_request_status.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: true,
    })),
  };
}

function toDirectoryRecord2(e: AgDirDefaultEntity): DirectoryRecord {
  return {
    value: e.value,
    label: e.label,
    description: e.description ?? undefined,
    // TODO: codegen
    // @ts-ignore
    active: e.active ?? false,
  };
}

export function toOrdersDirectoryEntity(
  response: GetDictionaryOrdersController_AgOrdersDictionaryResponse,
): OrdersDirectoryEntity {
  return {
    cities: response.dir_city.map(toDirectoryRecord2),
    containerHeights: response.dir_container_height.map(toDirectoryRecord2),
    containerTypes: response.dir_container_type.map(toDirectoryRecord2),
    deliveryTypes: response.dir_delivery_type.map(toDirectoryRecord2),
    containerClasses: response.dir_container_class.map(toDirectoryRecord2),
    howToCarryOptions: response.dir_how_to_carry.map(toDirectoryRecord2),
    // temp: response.dir_temperature,
    paymentTypes: response.dir_payment.map(toDirectoryRecord2),
    terminals: response.dir_terminal.map((t) => ({
      value: t.value,
      label: t.label,
      description: t.description ?? undefined,
      // TODO: codegen
      // @ts-ignore
      active: t.active ?? false,
      type: t.type,
      cityTerminal: t.city_terminal,
      station: t.station ?? undefined,
      paragraph: t.paragraph ?? undefined,
      addressTerminal: t.address_terminal,
      addressStation: t.address_station ?? undefined,
      lat: t.latitude_terminal,
      lon: t.longitude_terminal,
      companyName: t.company_name ?? undefined,
      companyInn: t.company_inn ?? undefined,
    })),
    orderStatuses: response.dir_status.map(toDirectoryRecord2),
    orderOptions: response.dir_option.map((o) => ({
      value: o.value,
      label: o.label,
      description: o.description ?? undefined,
      // TODO: codegen
      // @ts-ignore
      active: o.active ?? false,
      cities: o.city,
      deliveryTypes: o.delivery_type,
      containerTypes: o.container_type,
    })),
  };
}

export function toDocsPackageDirectoryEntity(
  response: DirDocsAllController_AgDirDocsAllResponse & { document_types_for_digitization: AgDirTypeEntityFront[] },
): DocsPackageDirectoryEntity {
  return {
    packageTypes: response.dir_document_package_type?.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: e.active,
    })),
    packageStatuses: response.dir_document_package_status?.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: e.active,
    })),
    moverDocumentPackageFileType: response.mover_document_package_file_type?.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: e.active,
    })),
    customerDocumentPackageFileType: response.customer_transport_document_file_type?.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: e.active,
    })),
    documentTypesForDigitization: response.document_types_for_digitization?.map(toDocumentTypeEntity),
  };
}

export function toDocumentDirectoryEntity(
  response:
    | DirDocumentCustomerController_AgDirDocumentAllResponse
    | DirDocumentMoverController_AgDirDocumentAllResponse,
): DocumentDirectoryEntity {
  return {
    types: response.dir_type?.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: e.active,
    })),
    mimeTypes: response.dir_mime_type?.map((e) => ({
      value: e.value,
      label: e.label,
      description: e.description ?? undefined,
      active: e.active,
    })),
  };
}
