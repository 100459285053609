import { clientDefault } from "../client";
import {
  GET_ALL_DOCUMENT_DIRECTORY,
  GET_BILLING_DIRECTORY,
  GET_BOXES_DIRECTORY,
  GET_CUSTOMER_DOCUMENT_DIRECTORY,
  GET_DOCS_PACKAGE_DIRECTORY,
  GET_MOVER_DOCUMENT_DIRECTORY,
  GET_NDS_DIRECTORY,
  GET_ORDERS_DIRECTORY,
  GET_SECURITY_DIRECTORY,
  GET_SUPPORT_DIRECTORY,
  GET_TRANSPORT_DIRECTORY,
  GET_WORKFLOW_DIRECTORY,
} from "../endpoints";
import {
  AgDirTypeEntityFront,
  DictionaryBillingController_BillingDictionaryResponse,
  DirDocsAllController_AgDirDocsAllResponse,
  DirDocumentAllControllerV2_AgDirDocumentAllResponse,
  DirDocumentCustomerController_AgDirDocumentAllResponse,
  DirDocumentMoverController_AgDirDocumentAllResponse,
  DirSecurityAllController_AgSecurityDictionaryResponse,
  DirSupportTicketAllController_SupportTicketDictionaryResponse,
  DirTransportAllController_AgTransportDictionaryResponse,
  DirWorkFlowAllController_AgWorkFlowDictionaryResponse,
  GetDictionaryOrdersController_AgOrdersDictionaryResponse,
  GetDocsBoxConfigController_AgDocsBoxConfigsResponse,
} from "../codegen";

export function getBoxesDirectory(): Promise<GetDocsBoxConfigController_AgDocsBoxConfigsResponse> {
  return clientDefault
    .get<GetDocsBoxConfigController_AgDocsBoxConfigsResponse>(GET_BOXES_DIRECTORY)
    .then((response) => response.data);
}

export function getSupportDirectory(): Promise<DirSupportTicketAllController_SupportTicketDictionaryResponse> {
  return clientDefault
    .get<DirSupportTicketAllController_SupportTicketDictionaryResponse>(GET_SUPPORT_DIRECTORY)
    .then((response) => response.data);
}

export function getSecurityDirectory(): Promise<DirSecurityAllController_AgSecurityDictionaryResponse> {
  return clientDefault
    .get<DirSecurityAllController_AgSecurityDictionaryResponse>(GET_SECURITY_DIRECTORY)
    .then((response) => response.data);
}

export function getDocumentsDirectory(): Promise<DirDocumentAllControllerV2_AgDirDocumentAllResponse> {
  return clientDefault
    .get<DirDocumentAllControllerV2_AgDirDocumentAllResponse>(GET_ALL_DOCUMENT_DIRECTORY)
    .then((response) => response.data);
}

export function getTransportDirectory(): Promise<DirTransportAllController_AgTransportDictionaryResponse> {
  return clientDefault
    .get<DirTransportAllController_AgTransportDictionaryResponse>(GET_TRANSPORT_DIRECTORY)
    .then((response) => response.data);
}

export function getBillingDirectory(): Promise<DictionaryBillingController_BillingDictionaryResponse> {
  return clientDefault
    .get<DictionaryBillingController_BillingDictionaryResponse>(GET_BILLING_DIRECTORY)
    .then((response) => response.data);
}

export function getOrdersDirectory(): Promise<GetDictionaryOrdersController_AgOrdersDictionaryResponse> {
  return clientDefault
    .get<GetDictionaryOrdersController_AgOrdersDictionaryResponse>(GET_ORDERS_DIRECTORY)
    .then((response) => response.data);
}

export function getDocsPackageDirectory(): Promise<
  DirDocsAllController_AgDirDocsAllResponse & { document_types_for_digitization: AgDirTypeEntityFront[] }
> {
  return clientDefault
    .get<DirDocsAllController_AgDirDocsAllResponse & { document_types_for_digitization: AgDirTypeEntityFront[] }>(
      GET_DOCS_PACKAGE_DIRECTORY,
    )
    .then((response) => response.data);
}

export function getNdsDirectory(): Promise<DirDocsAllController_AgDirDocsAllResponse> {
  return clientDefault
    .get<DirDocsAllController_AgDirDocsAllResponse>(GET_NDS_DIRECTORY)
    .then((response) => response.data);
}

export function getCustomerDocumentsDirectory(): Promise<DirDocumentCustomerController_AgDirDocumentAllResponse> {
  return clientDefault
    .get<DirDocumentCustomerController_AgDirDocumentAllResponse>(GET_CUSTOMER_DOCUMENT_DIRECTORY)
    .then((response) => response.data);
}

export function getMoverDocumentsDirectory(): Promise<DirDocumentMoverController_AgDirDocumentAllResponse> {
  return clientDefault
    .get<DirDocumentMoverController_AgDirDocumentAllResponse>(GET_MOVER_DOCUMENT_DIRECTORY)
    .then((response) => response.data);
}

export function getWorkflowDirectory(): Promise<DirWorkFlowAllController_AgWorkFlowDictionaryResponse> {
  return clientDefault
    .get<DirWorkFlowAllController_AgWorkFlowDictionaryResponse>(GET_WORKFLOW_DIRECTORY)
    .then((response) => response.data);
}
