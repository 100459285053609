import { clientDefault, clientWithAuth } from "../client";
import {
  CLEAN_ADDRESS,
  GET_DRIVER_TASK,
  GET_GEO_POSITION,
  GET_NEWS_API,
  GET_ONE_NEWS_API,
  POST_MAIN_FORM,
} from "../endpoints";
import {
  CleanAddressController_AddressRequest,
  CleanAddressController_ValidAddressResponse,
  GeoAddressController_AddressGeoRequest,
  GeoAddressController_AddressGeoResponse,
  GetDriverTaskViaLinkController_AgDriverTaskResponse,
  MainFormApplicationController_ApplicationMainRequest,
  MainFormApplicationController_ResultBoolResponse,
  NewsController_NewsResponse,
  NewsItemPageController_NewsItemResponse,
  PublicCleanAddressController_AddressRequest,
  PublicCleanAddressController_ValidAddressResponse,
} from "../codegen";
import { GetNewsRequest, GetOneNewsRequest } from "@/domain";

/**
 * заявка на подключение
 * docs: http://84.201.143.108:8990/front/#tag/Main_page/operation/v1.0Main_pageMain_formaSend
 */
export function sendRequestForm(
  request: MainFormApplicationController_ApplicationMainRequest,
): Promise<MainFormApplicationController_ResultBoolResponse> {
  return clientDefault
    .post<MainFormApplicationController_ResultBoolResponse>(POST_MAIN_FORM, request)
    .then((response) => response.data);
}

export function getNews(request: GetNewsRequest): Promise<NewsController_NewsResponse> {
  return clientDefault.get(`/${GET_NEWS_API}?limit=${request.limit}&offset=${request.offset}`);
}

export function getOneNews(request: GetOneNewsRequest): Promise<NewsItemPageController_NewsItemResponse> {
  return clientDefault.get(`/${GET_ONE_NEWS_API}?newsId=${request.newsId}`).then((response) => response.data);
}

export function cleanAddress(
  request: PublicCleanAddressController_AddressRequest,
): Promise<PublicCleanAddressController_ValidAddressResponse> {
  return clientDefault.post(CLEAN_ADDRESS, request).then((response) => response.data);
}

export function getGeoPosition(
  request: GeoAddressController_AddressGeoRequest,
): Promise<GeoAddressController_AddressGeoResponse> {
  return clientDefault.post(GET_GEO_POSITION, request).then((response) => response.data);
}

export function getDriverTask(code: string): Promise<GetDriverTaskViaLinkController_AgDriverTaskResponse> {
  const url = `${GET_DRIVER_TASK}?code=${code}`;
  return clientDefault.get(url).then((response) => response.data);
}
